'use client';

import React, { createContext, ReactNode, useEffect, useState } from 'react';

import { THEME_COLORS } from '@/lib/const';

type ThemeColors = {
  start: string;
  end: string;
  gradient: string;
};

type ThemeContextType = {
  theme: string;
  setTheme: (theme: string) => void;
  getThemeColors: (theme: string) => ThemeColors;
};

type RGBA = {
  r: number;
  g: number;
  b: number;
  a: number;
};

export const ThemeContext = createContext<ThemeContextType>({
  theme: 'default',
  setTheme: () => {},
  getThemeColors: () => ({
    start: 'rgba(81, 81, 198, 1.0)',
    end: 'rgba(136, 139, 244, 1.0)',
    gradient: 'linear-gradient(135deg, rgba(81, 81, 198, 1.0) 0%, rgba(136, 139, 244, 1.0) 100%)',
  }),
});

const generateGradient = (start: string, end: string): string =>
  `linear-gradient(135deg, ${start} 0%, ${end} 100%)`;

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = useState('default');

  const setPrimaryGradient = (startColor: string, endColor: string) => {
    const root = document.documentElement;
    const computedStyle = getComputedStyle(root);
    const currentStartColor = computedStyle.getPropertyValue('--primary-start').trim();
    const currentEndColor = computedStyle.getPropertyValue('--primary-end').trim();

    graduallyChangeColor('--primary-start', currentStartColor, startColor, 70);
    graduallyChangeColor('--primary-end', currentEndColor, endColor, 70);
  };

  useEffect(() => {
    const { start, end } = getThemeColors(theme);
    setPrimaryGradient(start, end);
  }, [theme]);

  const handleSetTheme = (newTheme: string) => {
    setTheme(newTheme);
    document.documentElement.className = newTheme;

    const { start, end } = getThemeColors(newTheme);
    setPrimaryGradient(start, end);
  };

  const getThemeColors = (theme: string): ThemeColors => {
    const colors = THEME_COLORS[theme] || THEME_COLORS['default'];
    return {
      ...colors,
      gradient: generateGradient(colors.start, colors.end),
    };
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme: handleSetTheme, getThemeColors }}>
      {children}
    </ThemeContext.Provider>
  );
};

export function parseRGBA(color: string): RGBA {
  // rgba形式の処理
  const rgbaMatch = color.match(/rgba\((\d+), (\d+), (\d+), ([\d.]+)\)/);
  if (rgbaMatch) {
    return {
      r: parseInt(rgbaMatch[1]),
      g: parseInt(rgbaMatch[2]),
      b: parseInt(rgbaMatch[3]),
      a: parseFloat(rgbaMatch[4]),
    };
  }

  // 16進数カラーコード (#RRGGBB) の処理
  const hexMatch = color.match(/^#([0-9a-fA-F]{6})$/);
  if (hexMatch) {
    const hex = hexMatch[1];
    return {
      r: parseInt(hex.substring(0, 2), 16),
      g: parseInt(hex.substring(2, 4), 16),
      b: parseInt(hex.substring(4, 6), 16),
      a: 1.0, // alpha値が指定されていない場合は1.0とする
    };
  }

  throw new Error('Invalid color format');
}

function interpolateColor(color1: RGBA, color2: RGBA, progress: number): string {
  const r = Math.round(color1.r + (color2.r - color1.r) * progress);
  const g = Math.round(color1.g + (color2.g - color1.g) * progress);
  const b = Math.round(color1.b + (color2.b - color1.b) * progress);
  const a = color1.a + (color2.a - color1.a) * progress;

  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

function graduallyChangeColor(key: string, color1: string, color2: string, duration: number) {
  const root = document.documentElement;
  const rgba1 = parseRGBA(color1);
  const rgba2 = parseRGBA(color2);

  let startTime: number | null = null;

  function step(timestamp: number) {
    if (!startTime) {
      startTime = timestamp;
    }
    const progress = Math.min((timestamp - startTime) / duration, 1);
    const currentColor = interpolateColor(rgba1, rgba2, progress);
    root.style.setProperty(key, currentColor);

    if (progress < 1) {
      requestAnimationFrame(step);
    }
  }

  requestAnimationFrame(step);
}
