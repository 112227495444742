/******************************************************************************************
 *  *  *  * Redis
 * ****************************************************************************************/

import { ResizeOptions, WebpOptions } from 'sharp';

/**
 * Redisのキャッシュ時間を定義（開発向けに30秒）
 **/
export const REDIS_EXPIRE_SECONDS: number = 30;

/******************************************************************************************
 *  *  *  * OpenSearch
 * ****************************************************************************************/
/**
 * topicとcommentを保持するOpenSearchのindex名
 */
export const OPENSEARCH_INDEX_NAME: string = 'sns_posts';
/**
 * hashtagを保持するOpenSearchのindex名
 */
export const OPENSEARCH_HASHTAG_MASTER_INDEX_NAME: string = 'sns_hashtags';

/**
 * OpenSearchの検索用 LIMIT（size）
 */
export const OPENSEARCH_DEFAULT_SIZE: number = 10;

/******************************************************************************************
 *  *  *  * ThemeColor
 * ****************************************************************************************/
export const THEME_COLORS: Record<string, { start: string; end: string }> = {
  red: { start: 'rgba(220, 38, 38, 1.0)', end: 'rgba(248, 113, 113, 1.0)' },
  blue: { start: 'rgba(79, 70, 229, 1.0)', end: 'rgba(129, 140, 248, 1.0)' },
  green: { start: 'rgba(22, 163, 74, 1.0)', end: 'rgba(74, 222, 128, 1.0)' },
  orange: { start: 'rgba(234, 88, 12, 1.0)', end: 'rgba(251, 146, 60, 1.0)' },
  purple: { start: 'rgba(139, 0, 139, 1.0)', end: 'rgba(218, 112, 214, 1.0)' },
  lightBlue: { start: 'rgba(75, 179, 231, 1.0)', end: 'rgba(128, 207, 247, 1.0)' },
  default: { start: 'rgba(81, 81, 198, 1.0)', end: 'rgba(136, 139, 244, 1.0)' },
};

/******************************************************************************************
 *  *  *  * ResponsiveSize
 * ****************************************************************************************/
export const RESPONSIVE_SIZE: Record<string, number> = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
};

/******************************************************************************************
 *  *  *  * Application
 * ****************************************************************************************/
/**
 * 投稿できる画像枚数を定義（一旦4枚で設定）
 **/
export const MAX_NUMBER_PHOTOS: number = 4;

/**
 * ページネーション時に追加で表示するページ個数
 **/
export const DISPLAY_NAVIGATION_COUNT = 2;

/**
 * メンションされる文字列のマークアップテキスト
 **/
export const MENTIONS_MARKUP_TEXT = (type: string) => `@[__display__](${type}|__id__)`;

/**
 * ハッシュタグされる文字列のマークアップテキスト
 **/
export const HASHTAGS_MARKUP_TEXT: string = '#[__display__](__id__)';

/**
 * ハッシュタグを新規作成時のマークアップテキスト
 */
export const USER_CREATE_HASHTAG: string = 'USER_CREATE_HASHTAG';

/**
 * １度に表示する最大グループ数
 */
export const CATEGORYLIST_LIMITED_COUNT: number = 10;

/**
 * １度に表示する最大トピック数
 */
export const TOPICLIST_LIMITED_COUNT: number = 10;

/**
 * １度に表示する最大コメント数
 */
export const COMMENT_LIMITED_COUNT: number = 30;

/**
 * １度に表示する最大タイムライントピック数
 */
export const TOPIC_LIMITED_COUNT: number = 10;

/**
 * タイムライント状で表示するコメント数
 */
export const TIMELINE_COMMENT_LIMITED_COUNT: number = 3;

/**
 * １度に表示する通知件数
 */
export const NOTIFICATEION_LIMITED_COUNT: number = 10;

/**
 * １度に表示する設定類語一覧の件数
 */
export const SYNONYM_LIMITED_COUNT: number = 100;

/**
 * サムネイルの変換設定
 */
export const THUMBNAIL_SETTING: ResizeOptions = {
  width: 640,
  height: 640,
  fit: 'cover',
  position: 'center',
};
export const THUMBNAIL_WEBP_SETTING: WebpOptions = {
  quality: 80,
};

/**
 * インプレッションの更新間隔
 */
export const IMPRESSION_UPDATE_INTERVAL = 20000; // 20秒

/**
 * cronのパターン
 */
export const UTC_0_CRON_PATTERN = '0 0 * * *'; // [UTC] 0分 0時 *日 *月 *曜日
export const JST_0_CRON_PATTERN = '0 9 * * *'; // [UTC] 0分 9時 *日 *月 *曜日

/**
 * トピックトレンド表示件数
 */
export const TOPIC_TREND_COUNT = 5;
